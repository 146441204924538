import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6d55802b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "showContainre" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  key: 0,
  class: "recommend-list"
}
const _hoisted_4 = ["onMouseenter", "onMouseleave"]
const _hoisted_5 = { class: "item-top" }
const _hoisted_6 = {
  style: { width: '40px', height: '40px', marginRight: '6px' },
  alt: ""
}
const _hoisted_7 = { style: {
              fontWeight: '700',
              verticalAlign: '10px',
              fontSize: '14px',
            } }
const _hoisted_8 = { class: "item-bottom" }
const _hoisted_9 = {
  key: 0,
  class: "masker"
}

import { computed, reactive, ref, defineAsyncComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import MaskVue from "@/template/Mask.vue";

import { useRecommend } from "@/pinia/api/useRecommend.js";
import detault from "@/assets/default_logo copy.png";


export default {
  setup(__props) {

const state = useRecommend(); // pinia
// 调用一下
onMounted(() => {
  state.initRecommendList({ page: 1 }, true); // 获取公司列表数据
});

// router 获取
const router = useRouter();
const ShowMaskerState = reactive({
  flag: "",
  isShowMasker: false,
});

/* 展示 masker */
const showMasker = (item) => {
  ShowMaskerState.flag = item;
  ShowMaskerState.isShowMasker = true;
};

/* 隐藏 masker */
const hideMasker = (e) => {
  ShowMaskerState.flag = "";
  ShowMaskerState.isShowMasker = false;
};

// 点击进入店铺的时候
const loginStore = (id) => {
  const { href } = router.resolve(`/${id}/company/detail`);
  window.open(href, "_blank");
};

return (_ctx, _cache) => {
  const _component_el_empty = _resolveComponent("el-empty")
  const _directive_logo = _resolveDirective("logo")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_unref(state).recommendList.length)
        ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(state).recommendList, (item, index) => {
              return (_openBlock(), _createElementBlock("li", {
                onMouseenter: $event => (showMasker(item)),
                onMouseleave: $event => (hideMasker(item)),
                class: "recommend-item",
                key: item.name
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [
                    [_directive_logo, item.logo]
                  ]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(item.name), 1)
                ]),
                _createElementVNode("div", _hoisted_8, "主营：" + _toDisplayString(item.main_products), 1),
                (_unref(ShowMaskerState).flag == item)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(MaskVue, {
                        onLoginStore: loginStore,
                        id: item.id
                      }, null, 8, ["id"])
                    ]))
                  : _createCommentVNode("", true)
              ], 40, _hoisted_4))
            }), 128))
          ])), [
            [_directive_loading, _unref(state).loading]
          ])
        : (_openBlock(), _createBlock(_component_el_empty, {
            key: 1,
            description: "暂无更多公司信息"
          }))
    ])
  ]))
}
}

}